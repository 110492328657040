@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap');

html {
  font-size: 62.5%;
}

body {
  font-family: 'Raleway', 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  font-display: swap;
  color: #333;
  margin: 0;
  padding: 0;
}

/* Top Page */
.top-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(250.6deg, #6A70FF 7.3%, rgba(255, 53, 53, 0.4) 98.63%);
  background-size: 400% 400%;
  /* animation: gradient 12s ease infinite; */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* Header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  backdrop-filter: blur(5px);
  width: 100%;
  padding: 0.6rem 0;
  background: rgba(255, 255, 255, 0.25);
}

.header>a {
  font-size: 1.4rem;
  color: inherit;
  margin: 0 2rem;
}

/* Title */
h1 {
  margin: 0;
  padding-top: 3rem;
  font-family: "Raleway";
  text-align: center;
  font-weight: 400;
  font-size: 6rem;
  line-height: 5rem;
  letter-spacing: 1rem;
  color: #fff;
}

/* Selector */
.selector-container {
  margin: 4rem 0;
}

.selector-container>select {
  border-radius: 40px;
  border: transparent;
  width: 498px;
  height: 27px;
  padding: 0.5rem 1rem;
  appearance: none;
  color: #fff;
  background: linear-gradient(250.6deg, #6A70FF 7.3%, rgba(255, 53, 53, 0.4) 98.63%);
}

.selector-container>select:active,
.selector-container>select:focus,
.selector-container>select:hover {
  outline: 0;
  border: none;
  cursor: pointer;
}

.selector-container>button {
  width: 98px;
  height: 27px;
  border-radius: 0 40px 40px 0;
  background: #4473ff;
  border: none;
  border-left: 2px solid #fff;
  color: white;
  padding: 0.6rem 2rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.selector-container>button:hover {
  color: #4473ff;
  background-color: #fff;
  transition: 0.3s all;
}

/* Results */
.results-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  min-height: 300px;
  padding: 1rem;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  box-shadow: 4px 4px 13px 5px rgba(0, 0, 0, 0.25);
}

.results-container p span {
  font-size: 3.4rem;
  color: #4473ff;
  margin-left: 2rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

/* For responsive design */
@media only screen and (max-width: 600px) {
  h1 {
    margin-top: 3rem;
    font-size: 4rem;
    line-height: 2rem;
  }

  .selector-container>select {
    width: 370px;
  }

  .results-container {
    width: 350px;
  }
}

/* World Page */
.world-page-container {
  height: 100%;
  padding: 4rem 3rem;
  background: linear-gradient(250.6deg, #6A70FF 7.3%, rgba(255, 53, 53, 0.4) 98.63%);
  background-size: 300% 300%;
  /* 	animation: gradient 14s ease infinite; */
}

.card-container {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.card {
  display: flex;
  justify-content: center;
  padding: 1rem 3rem;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  box-shadow: 4px 4px 13px 5px rgba(0, 0, 0, 0.25);
}

.card h2 {
  font-weight: 500;
  text-align: center;
}

.card p span {
  font-size: 2.0rem;
  color: #ff57ae;
  margin-left: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

/* Loading */
.loading {
  color: #fe5c5c;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}